import React from "react";
import { Container } from "@mui/material";
import styled from "@emotion/styled";
import { CustomGrid, DataImage } from "../../../Styles/style";
import aboutLayer from "../../../../assets/images/aboutLayer.png";
import aboutLayerMobile from "../../../../assets/images/aboutLayerMobile.png";
import Paragraph from "../../Paragraph";
import backgroundImage from "../../../../assets/images/aboutBg.png";
import aboutBottom from "../../../../assets/images/aboutBottom.png";
import dady from "../../../../assets/images/dady.png";
import baby from "../../../../assets/images/baby.png";
import CustomButton from "../../CustomBtn";
import zIndex from "@mui/material/styles/zIndex";
const ContinerWraper = styled(Container)`
  overflow: hidden;
  /* min-height: 100vh; */
  width: 100%;
  padding: 60px 0 !important;
  position: relative;
  z-index: 0;
  background-color: #009017;
  display: flex;
  align-items: center;
  background-blend-mode: overlay;
  @media (max-width: 599px) {
    /* min-height: auto; */
  }
`;

const InnerContinerWraper = styled(Container)`
  min-height: 100%;
  width: 100%;
  background-image: url(${backgroundImage});
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  display: grid;
  place-items: center;
  /* z-index: 1; */
  padding: 50px 50px !important;
  @media (max-width: 599px) {
    padding: 20px !important;
  }
  /* ::after{
    content: "";
    position:absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: url(${aboutLayer});
    background-position: right;
    background-repeat: no-repeat;
    background-size: cover;
    mix-blend-mode: overlay;
    display: none;
    @media(max-width: 599px){
      display: block;
    }
  } */
`;
const AboutImage = styled(DataImage)` 
  display : block;
  @media (max-width: 599px) {
    display : none;
  }
`;
const AboutImageMobile = styled(DataImage)`
  display : none;
  @media (max-width: 599px) {
    display : block;
  }
`;

const AboutSection = () => {
  return (
    <>
      <ContinerWraper maxWidth="xxl" id="about">
      <DataImage mw="100%" disp="none" dispmain="block" style={{zIndex:"0",position:"absolute",top:"0",left:"0",mixBlendMode:"overlay"}} src={aboutLayer} />
      <DataImage mw="100%" dispmain="none"disp="block" style={{zIndex:"0",position:"absolute",top:"0",left:"0",mixBlendMode:"overlay"}} src={aboutLayerMobile} />
        <InnerContinerWraper maxWidth="xl">
          <CustomGrid container jc="space-between"  data-aos="fade-up">
            <CustomGrid container item xs={12} g="20px">
              <CustomGrid item xs={12}>
              <Paragraph
                // data-aos="zoom-in"
                // variant="h1"
                fontSize="clamp(3.75rem, 3.0357rem + 3.5714vw, 6.25rem)"
                fontWeight="bolder"
                fontFamily="Cute"
                lineHeight=".8"
                textAlign="center"
              >
               About
              </Paragraph>
              </CustomGrid>
              <CustomGrid
               
                data-aos-duration="1000"
                item
                xs={12}
                sm={10}
              >
                <Paragraph textAlign="center">
                Baby Borpa is the life of the crypto party, inspired by our daddy, Borpa. We are here to shake up the crypto space with our playful spirit and solid tokenomics.<br/><br/>Join our meme-loving crew and let’s make some crypto magic together!
                </Paragraph>
              </CustomGrid>
              <CustomGrid xs={12}>
                  <CustomButton href="https://t.me/BabyBorpaToken"  target="blank"
                   sx={{position:"relative",zIndex:"99999999999 !important"}}
                  bgc="#fff"
                  c="#000"
                  hbgc="#fff"
                  hc="#000">
                  Join Community
                  </CustomButton>
              </CustomGrid>
              <CustomGrid xs={12} fd="column" p="70px 0 0">
                <AboutImage mw="70%" style={{marginTop:"106px"}} src={aboutBottom} />
                <AboutImageMobile mw="90%"  src={dady} />
                <AboutImageMobile mw="90%"  src={baby} />
              </CustomGrid>
            </CustomGrid>
          </CustomGrid>
        </InnerContinerWraper>
      </ContinerWraper>
    </>
  );
};

export default AboutSection;
