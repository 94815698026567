import React from "react";
import DesktopMenu from "./component/DesktopMenu";
import MobileMenu from "./component/MobileMenu";
import { Container } from "@mui/material";
import x from "../../../assets/images/x.png"
import tg from "../../../assets/images/tg.png"
import { DataImage } from "../../Styles/style";

const LockerMenu = (props) => {
  const menuList = [
    // You can pass 4 perameters for menu title, link, target and customClass
    {
      title: "About",
      link: "#about",
    },
    {
      title: "Presale",
      link: "#presale",
    },
    {
      title: "Tokenomics",
      link: "#tokenomics",
    },
    {
      title: "Roadmap",
      link: "#roadmap",
    },
    {
      title:<DataImage mw="40px" src={x} />,
      link: "https://x.com/BabyBorpaToken",
      target: "blank"
    },
    {
      title:<DataImage mw="40px" src={tg} />,
      link: "https://t.me/BabyBorpaToken",
      target: "blank"
    },
  ];
  return (
        <Container maxWidth="xl" sx={{padding:"0 !important"}}>
          <DesktopMenu menuList={menuList} />
          <MobileMenu menuList={menuList} />
        </Container>
  );
};

export default LockerMenu;
