import React from "react";
import { Container } from "@mui/material";
import styled from "@emotion/styled";
import { CustomGrid,  DataImage } from "../../../Styles/style";
import Paragraph from "../../Paragraph";
import RoadmapBg from "../../../../assets/images/RoadmapBg.png";
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import Carousel from "../../carousel";
const ContinerWraper = styled(Container)`
  overflow: hidden;
  /* min-height: 100vh; */
  width: 100%;
  padding: 60px 0 !important;
  position: relative;
  z-index: 0;
  background:#007B15 ;
  @media (max-width: 599px) {
    /* min-height: auto; */
  }
`;

const InnerContinerWraper = styled(Container)`
  /* min-height: calc(100vh); */
  width: 100%;
  padding: 0 50px !important;
  display: grid;
  place-items: center;
  z-index: 1;
  @media (max-width: 599px) {
    min-height: auto;
    background-position: left;
    padding: 0 20px !important;
    /* min-height: auto; */
    background-image: none;
  }
`;
const StyledParagraph = styled(Paragraph)`
  background:#065317;
  display: flex;
  align-items: center;
  gap:5px;
  border-radius: 10px;
  padding: 10px;
  width: 100%;

`;
const phase0 =  [
  {
    text:"Conceptualize $BBPRPA Token",
    checked : true
  },
  {
    text:"Build Website",
    checked : true
  },
  {
    text:"Deploy Token",
    checked : true
  },
  {
    text:"Conduct Presale On PinkSale",
    checked : true
  },
  {
    text:"Start Community Building",
    checked : true
  },
]
const phase1 =  [
  {
    text:"Launch in Raydium",
    checked : false
  },
  {
    text:"List On CoinGecko",
    checked : false
  },
  {
    text:"List On CoinMarketCap",
    checked : false
  },
  {
    text:"Community AMA",
    checked : false
  },
  {
    text:"Market Via Social Media",
    checked : false
  },
]
const phase2 =  [
  {
    text:"Listings on new exchanges",
    checked : false
  },
  {
    text:"Intensify Marketing Efforts",
    checked : false
  },
  {
    text:"New partners",
    checked : false
  },
  {
    text:"Sustain Growth",
    checked : false
  }
]
const RoadMap = () => {
  
  return (
    <>
      <ContinerWraper maxWidth="xxl" id="roadmap"  data-aos="fade-up">
      <DataImage mw="100%" style={{zIndex:"-1",position:"absolute",top:"80%",left:"0",transform:"translate(0,-80%)"}}  src={RoadmapBg} />
        <InnerContinerWraper maxWidth="xl">
          <CustomGrid
            container
            p="30px 0"
            fd="column"
            jc="space-around"
          >
            <CustomGrid item xs={12} fd="column" g="20px 0">
            <Paragraph
                // data-aos="zoom-in"
                // variant="h1"
                fontSize="clamp(3.75rem, 3.0357rem + 3.5714vw, 6.25rem)"
                fontWeight="bolder"
                fontFamily="Cute"
                lineHeight=".8"
                textAlign="center"
                color="#AAFE02"
              >Roadmap</Paragraph>
              <CustomGrid container item xs={12} g="20px" p="80px 0 0" display={{xs:"none",md:"flex"}}>
                <CustomGrid
                  // data-aos="fade-up"
                  // data-aos-duration="1000"
                  item
                  xs={12}
                  md={3.5}
                  jc="start"
                  ai="start"
                  fd="column"
                  g="10px"
                  sx={{
                    background: "#013B0A",
                    minHeight:"400px",
                    borderRadius: "5px",
                    rotate:"-1deg",
                    marginTop:"10px"
                  }}
                >
                  <CustomGrid
                    item
                    xs={12}
                    fd="column"
                    g="10px"
                    jc="start"
                    ai="start"
                    p="20px"
                  >
                    <Paragraph ta="left" fs="18px" fs599="16px" fm="Cute">
                      PHASE 0
                    </Paragraph>
                    <Paragraph variant="h2"fontWeight="bolder"
                    fontFamily="Cute"
                    lineHeight=".8"
                    textAlign="center">
                   Birth
                  </Paragraph>
                  {
                      phase0.map((item,index)=>(
                        <StyledParagraph>
                          {
                            item.checked ? 
                            <FiberManualRecordIcon disabled={item.checked} sx={{color:"#AAFE02"}}/>:
                            <FiberManualRecordIcon disabled={item.checked} sx={{color:"#9AE7AB"}}/>
                          }
                          {item.text}
                        </StyledParagraph>
                      ))
                    }
                  </CustomGrid>
                </CustomGrid>
                <CustomGrid
                  // data-aos="fade-up"
                  // data-aos-duration="1300"
                  item
                  xs={12}
                  md={3.5}
                  jc="start"
                  ai="start"
                  fd="column"
                  g="10px"
                  sx={{
                    background: "#013B0A",
                    minHeight:"400px",
                    borderRadius: "5px",
                  }}
                >
                  <CustomGrid
                    item
                    xs={12}
                    fd="column"
                    g="10px"
                    jc="start"
                    ai="start"
                    p="20px"
                  >
                    <Paragraph ta="left" fs="18px" fs599="16px" fm="Cute">
                      PHASE 1
                    </Paragraph>
                    <Paragraph variant="h2"fontWeight="bolder"
                    fontFamily="Cute"
                    lineHeight=".8"
                    textAlign="center">
                   First Steps
                  </Paragraph>
                    {
                      phase1.map((item,index)=>(
                        <StyledParagraph>
                          {
                            item.checked ? 
                            <FiberManualRecordIcon disabled={item.checked} sx={{color:"#AAFE02"}}/>:
                            <FiberManualRecordIcon disabled={item.checked} sx={{color:"#9AE7AB"}}/>
                          }
                          {item.text}
                        </StyledParagraph>
                      ))
                    }
                  </CustomGrid>
                </CustomGrid>
                <CustomGrid
                  // data-aos="fade-up"
                  // data-aos-duration="1600"
                  item
                  xs={12}
                  md={3.5}
                  jc="start"
                  ai="start"
                  fd="column"
                  g="10px"
                  sx={{
                    background: "#013B0A",
                    minHeight:"400px",
                    borderRadius: "5px",
                    rotate:"1deg",
                    marginTop:"10px"
                  }}
                >
                  <CustomGrid
                    item
                    xs={12}
                    fd="column"
                    g="10px"
                    jc="start"
                    ai="start"
                    p="20px"
                  >
                    <Paragraph ta="left" fs="18px" fs599="16px" fm="Cute">
                      PHASE 2
                    </Paragraph>
                    <Paragraph variant="h2"fontWeight="bolder"
                    fontFamily="Cute"
                    lineHeight=".8"
                    textAlign="center">
                   Nurturing
                  </Paragraph>
                  {
                      phase2.map((item,index)=>(
                        <StyledParagraph>
                          {
                            item.checked ? 
                            <FiberManualRecordIcon disabled={item.checked} sx={{color:"#AAFE02"}}/>:
                            <FiberManualRecordIcon disabled={item.checked} sx={{color:"#9AE7AB"}}/>
                          }

                          {item.text}
                        </StyledParagraph>
                      ))
                    }
                  </CustomGrid>
                </CustomGrid>
              </CustomGrid>
              <Carousel/>
            </CustomGrid>
          </CustomGrid>
        </InnerContinerWraper>
      </ContinerWraper>
    </>
  );
};

export default RoadMap;
