import React, { useState } from "react";
import { Container } from "@mui/material";
import styled from "@emotion/styled";
import { CustomGrid, DataImage } from "../../../Styles/style";
import backgroundImage from "../../../../assets/images/tokenBg.png";
import RingChart from "../../../../assets/images/RingChart.png";
import dexscreener from "../../../../assets/images/dexscreener.png";
import dextools from "../../../../assets/images/dextools.png";
import raydium from "../../../../assets/images/raydium.png"; 
import ban from "../../../../assets/images/ban-icon.png";
import FileCopyIcon from "@mui/icons-material/FileCopy";
import Paragraph from "../../Paragraph";
import CustomButton from "../../CustomBtn";
const ContinerWraper = styled(Container)`
  overflow: hidden;
  min-height: 100vh;
  width: 100%;
  background-color: #009017;
  background-image: url(${backgroundImage});
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  padding: 60px 0 !important;
  position: relative;
  z-index: 0;
  @media (max-width: 599px) {
    /* min-height: auto; */
  }
`;
const CircleGrid = styled(CustomGrid)`
  background-image: url(${RingChart});
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  min-height: 400px;
  margin:  0;
  @media (max-width: 599px) {
    /* min-height: auto; */
  }
`;


const InnerContinerWraper = styled(Container)`
  min-height: calc(100vh);
  width: 100%;
  padding: 0 50px !important;
  z-index: 1;
  @media (max-width: 599px) {
    min-height: auto;
    background-position: left;
    padding: 0 20px !important;
    /* min-height: auto; */
    background-image: none;
  }
`;

const Tokenomics = () => {
  const [showToast, setShowToast] = useState(false);
  const addressToCopy = "00000000000000Xx0000x0000xxxx0";

  const handleCopy = () => {
    navigator.clipboard.writeText(addressToCopy);
    setShowToast(true);
    setTimeout(() => {
      setShowToast(false);
    }, 2000);
  };
  return (
    <>
      <ContinerWraper maxWidth="xxl" id="tokenomics">
        <InnerContinerWraper maxWidth="xl">
          <CustomGrid
           data-aos="fade-up"
            container
            p="30px 0"
            fd="column"
            jc="space-around"
            sx={{ minHeight: "calc(100vh - 67px)" }}
          >
            <CustomGrid item xs={12} fd="column" g="20px 0">
            <Paragraph
                // data-aos="zoom-in"
                // variant="h1"
                fontSize="clamp(3.75rem, 3.0357rem + 3.5714vw, 6.25rem)"
                fontWeight="bolder"
                fontFamily="Cute"
                lineHeight=".8"
                textAlign="center"
                color="#AAFE02"
              >
               Tokenomics
              </Paragraph>
              <CustomGrid
                container
                item
                xs={12}
                md={8}
                // md={6}
                g="20px 0"
                jc="space-between"
              >
                <CustomGrid
                  item
                  xs={12}
                  sm={3}
                  fd="column"
                  ai="center"
                >
                  {/* <DataImage mw="50%" src={miniwagy} /> */}
                  <Paragraph variant="h6" textAlign="center">
                  50% Liquidity
                  </Paragraph>
                </CustomGrid>
                <CircleGrid
                  // data-aos-duration="1000"
                  item
                  xs={12}
                  sm={6}
                  fd="column"
                  ai="center"
                > 
                   <Paragraph variant="h2"fontWeight="bolder"
                    fontFamily="Cute"
                    lineHeight=".8"
                    textAlign="center">
                   1 Billion
                  </Paragraph>
                  <Paragraph variant="h6" textAlign="center">
                  Total Supply
                  </Paragraph>
                </CircleGrid>
                <CustomGrid
                  // data-aos-duration="1300"
                  item
                  xs={12}
                  sm={3}
                  fd="column"
                  ai="center"
                >
                  {/* <DataImage mw="50%" src={miniwagy} /> */}
                  <Paragraph variant="h6" textAlign="center">
                    50% Presale
                  </Paragraph>
                </CustomGrid>
              </CustomGrid> 
              <CustomGrid
                container
                item
                xs={12}
                sm={8}
                md={6} 
                fd="row"
                jc="center"
                g="10px"
                flexWrap={"nowrap"}
              > 
                <CustomGrid  
                 item  
                 xs={12}  
                 sm={6} 
                 md={6}
                 jc="center" 
                 ai="center"
                 >  
                 <DataImage mw="30px" style={{marginRight:"5px"}} src={ban} /> 
                 <Paragraph fontSize={{xs:"1.1rem",lg:"1.25rem"}} fs="24px" fs599="16px" color="white">
                   No Taxes
                  </Paragraph>
                 </CustomGrid>  
                 <CustomGrid  
                 item  
                 xs={12}  
                 sm={6} 
                 md={6}  
                 jc="center" 
                 ai="center"
                 >  
                 <DataImage mw="30px" style={{marginRight:"5px"}} src={ban} /> 
                 <Paragraph fontSize={{xs:"1.1rem",lg:"1.25rem"}} fs="18px" fs599="16px" color="white">
                   No Team Token
                  </Paragraph>
                 </CustomGrid>

              </CustomGrid>
              <CustomGrid
                // data-aos-duration="2800"
                container
                item
                xs={12}
                sm={10}
                md={6}
                p="10px 20px"
                ai="start"
                jc="space-between"
                sx={{
                  background: "#AAFE02",
                  boxShadow: "4px 4px 0px #000",
                  borderRadius: "10px",
                  position: "relative",
                }}
              >
                {showToast && (
                  <div
                    style={{
                      width: "300px",
                      height: "40px",
                      borderRadius: "10px",
                      position: "absolute",
                      top: "-12%",
                      left: "50%",
                      transform: "translate(-50%,-12%)",
                      display: "grid",
                      placeItems: "center",
                      background: "#000",
                      color: "#007813",
                      zIndex: "1",
                    }}
                  >
                    Contract Address Copied
                  </div>
                )}
                <Paragraph fs="18px" fs599="16px" color="#007813">
                  Contract Address:
                </Paragraph>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    gap:"5px"
                  }}
                >
                  <Paragraph fs="18px" fs599="16px" color="#007813">
                    TBA
                  </Paragraph>
                  <FileCopyIcon sx={{color:"#007813",cursor:"pointer"}} onClick={handleCopy}/>
                </div>
              </CustomGrid> 
     
              <CustomGrid
                container
                item
                xs={12}
                sm={8}
                md={6}

                jc="center"
                g="10px"
              >
                <CustomButton sx={{filter:"grayscale(1)",cursor:"not-allowed !important"}} ><DataImage mw="30px" style={{marginRight:"5px"}} src={dexscreener} />DexScreener</CustomButton>
                <CustomButton sx={{filter:"grayscale(1)",cursor:"not-allowed !important"}} ><DataImage mw="25px" style={{marginRight:"5px"}} src={dextools} />DexTools</CustomButton>
                <CustomButton sx={{filter:"grayscale(1)",cursor:"not-allowed !important"}} ><DataImage mw="25px" style={{marginRight:"5px"}} src={raydium} />Raydium</CustomButton>

              </CustomGrid>
            </CustomGrid>
          </CustomGrid>
        </InnerContinerWraper>
      </ContinerWraper>
    </>
  );
};

export default Tokenomics;
