import styled from "@emotion/styled";
import { Container, Grid, Link } from "@mui/material";
import React from "react";
import image1 from "../../../../assets/images/logo.png";
import footerBg from "../../../../assets/images/footerBg.png";
import dexscreener from "../../../../assets/images/dexscreenerIcon.png";
import dextools from "../../../../assets/images/dextoolsIcon.png";
import pinksaleIcon from "../../../../assets/images/pinksaleIcon.png";
import x from "../../../../assets/images/x.png";
import tg from "../../../../assets/images/tg.png";
import raydium from "../../../../assets/images/raydiumIcon.png";
import Paragraph from "../../Paragraph";
import {
  DataImage,
  CustomGrid,
} from "../../../Styles/style";
const ContinerWraper = styled(Container)`
  background-image: url(${footerBg});
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-color: #007B15;
  position: relative;
  height: auto;
  width: 100%;
  padding: 40px 0 0 !important;
`;


const Footer = () => {
  return (
    <>
      <ContinerWraper maxWidth="xxl">
        <Container maxWidth="xl">
          <Grid
            container
            sx={{
              display: "flex",
              justifyContent: "center",
              rowGap: "20px",
              zIndex: "1",
              columnGap: "30px",
              padding: "40px 0px",
            }}
          >
            <CustomGrid
              item
              xs={12}
              md={10}
              jc="space-evenly"
              p="0 0 20px"
              // data-aos="fade-up"
              // data-aos-duration="1000"
            >
            
              <DataImage mw="220px" src={image1} />
            </CustomGrid>
            <CustomGrid
              item
              xs={12}
              md={10}
              // data-aos="fade-up"
              // data-aos-duration="1000"
            >
            
            <Paragraph
                // data-aos="zoom-in"
                variant="h6"
              >
               Find Us On
              </Paragraph>
            </CustomGrid>
            <CustomGrid item xs={12}>
              <div style={{ display: "flex", gap: "10px" }}>
                <Link  href="https://t.me/BabyBorpaToken" target="blank">
                  <DataImage mw="40px" src={tg} />
                </Link>
                <Link  href="https://x.com/BabyBorpaToken" target="blank">
                  <DataImage mw="40px" src={x} />
                </Link>
                <Link sx={{filter:"grayscale(1)",cursor:"not-allowed !important"}}  
                // href="" target="blank"
                >
                  <DataImage mw="40px" src={pinksaleIcon} />
                </Link>
                <Link sx={{filter:"grayscale(1)",cursor:"not-allowed !important"}}  
                // href="" target="blank"
                >
                  <DataImage mw="40px" src={dexscreener} />
                </Link>
                <Link sx={{filter:"grayscale(1)",cursor:"not-allowed !important"}}  
                // href="" target="blank"
                >
                  <DataImage mw="40px" src={dextools} />
                </Link>
                <Link sx={{filter:"grayscale(1)",cursor:"not-allowed !important"}}  
                // href="" target="blank"
                >
                  <DataImage mw="40px" src={raydium} />
                </Link>
              </div>
            </CustomGrid>
            <CustomGrid item xs={12}>
              <Paragraph color="#fff">©2024 Baby Borpa. All rights reserved.</Paragraph>
            </CustomGrid>
          </Grid>
        </Container>
      </ContinerWraper>
    </>
  );
};

export default Footer;
