import React, { useState, useRef } from 'react';
import styled from '@emotion/styled';
import Paragraph from '../Paragraph';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';

const CarouselContainer = styled.div`
  display: none;
  justify-content: center;
  align-items: center;
  position: relative;
  width: 90vw;  // adjust as needed
  height: auto; // adjust as needed
  min-height: 400px;
  margin: 0 auto;
  @media(max-width: 899px){
    display: flex;
  }
`;

const Card = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: flex-start;
  gap: 10px;
  padding: 20px;
  min-width: 100%;
  background-color: #013B0A;
  min-height: 400px;
  border-radius: 5px;
  margin-top: 10px;
  transition: transform 0.5s ease;
  color: #fff;
  font-family: 'Arial', sans-serif;
  font-size: 1.5rem;
  position: absolute;
  top: 0;
  left: 0;
`;

const List = styled.ul`
  list-style: none;
  width: 100%;
  padding: 0;
`;

const StyledParagraph = styled(Paragraph)`
  background: #065317;
  display: flex;
  align-items: center;
  gap: 5px;
  border-radius: 10px;
  padding: 10px;
  width: 100%;
  margin-bottom: 10px;
`;

const Carousel = () => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const cards = [
    {
      phase: 'Phase 0',
      title: 'Birth',
      details: [
        { text: "Conceptualize $BBPRPA Token", checked: true },
        { text: "Build Website", checked: true },
        { text: "Deploy Token", checked: true },
        { text: "Conduct Presale On PinkSale", checked: true },
        { text: "Start Community Building", checked: true },
      ]
    },
    {
      phase: 'Phase 1',
      title: 'First Steps',
      details: [
        { text: "Launch in Raydium", checked: false },
        { text: "List On CoinGecko", checked: false },
        { text: "List On CoinMarketCap", checked: false },
        { text: "Community AMA", checked: false },
        { text: "Market Via Social Media", checked: false },
      ]
    },
    {
      phase: 'Phase 2',
      title: 'Nurturing',
      details: [
        { text: "Listings on new exchanges", checked: false },
        { text: "Intensify Marketing Efforts", checked: false },
        { text: "New partners", checked: false },
        { text: "Sustain Growth", checked: false },
      ]
    }
  ];
  const startXRef = useRef(0);
  const currentXRef = useRef(0);
  const draggingRef = useRef(false);

  const handleNext = () => {
    if (currentIndex < cards.length - 1) {
      setCurrentIndex(currentIndex + 1);
    }
  };

  const handlePrev = () => {
    if (currentIndex > 0) {
      setCurrentIndex(currentIndex - 1);
    }
  };

  const handleTouchStart = (e) => {
    startXRef.current = e.touches[0].clientX;
    draggingRef.current = true;
  };

  const handleTouchMove = (e) => {
    if (!draggingRef.current) return;
    currentXRef.current = e.touches[0].clientX;
  };

  const handleTouchEnd = () => {
    if (!draggingRef.current) return;
    const diff = startXRef.current - currentXRef.current;
    if (diff > 50) {
      handleNext();
    } else if (diff < -50) {
      handlePrev();
    }
    draggingRef.current = false;
  };

  return (
    <CarouselContainer
      onTouchStart={handleTouchStart}
      onTouchMove={handleTouchMove}
      onTouchEnd={handleTouchEnd}
    >
      {cards.map((card, index) => (
        <Card
          key={index}
          style={{
            transform: `translateX(${(index - currentIndex) * 100}%)`,
            zIndex: index === currentIndex ? 1 : 0,
            opacity: index === currentIndex ? 1 : 0,
          }}
        >
          <Paragraph ta="left" fs="18px" fs599="16px" fm="Cute">
            {card.phase}
          </Paragraph>
          <Paragraph variant="h2" fontWeight="bolder" fontFamily="Cute" lineHeight=".8" textAlign="center">
            {card.title}
          </Paragraph>
          <List>
            {card.details.map((detail, i) => (
              <StyledParagraph key={i}>
                <FiberManualRecordIcon
                  disabled={detail.checked}
                  sx={{ color: detail.checked ? "#AAFE02" : "#9AE7AB" }}
                />
                {detail.text}
              </StyledParagraph>
            ))}
          </List>
        </Card>
      ))}
    </CarouselContainer>
  );
};

export default Carousel;
