import styled from "@emotion/styled";
import { Container, Grid } from "@mui/material";
import React from "react";
import { CustomGrid, DataImage } from "../../../Styles/style";
import borpaMissed from "../../../../assets/images/borpaMissed.png";

import Paragraph from "../../Paragraph";
import CustomButton from "../../CustomBtn";
const ContinerWraper = styled(Container)`
  position: relative;
  display: grid;
  place-items: center;
  width: 100%;
  z-index: 0;
  background: #007B15;
  padding: 100px 0 !important;
  @media (max-width: 599px) {
    min-height: auto;
  }
`;


const Community = () => {
  return (
    <ContinerWraper maxWidth="xxl" id="community">
      <Container maxWidth="xl" sx={{position:"relative",background:"#013B0A",marginBottom:"80px"}}>
      <DataImage mw="360px" style={{position:"absolute",bottom:"-20%",right:"5%"}}  src={borpaMissed} />

        <Grid
          container
          sx={{
            display: "flex",
            justifyContent: "center",
            rowGap: "20px",
            zIndex: "1",
            columnGap: "30px",
            padding:{xs:"50px 20px 350px" ,sm:"50px 20px 150px"},
            
          }}
        >
          <CustomGrid
          data-aos="fade-up"
              container
              item
              xs={12}
              ai="start"
              fd="column"
              g="20px"
            >
             <Paragraph
                // data-aos="zoom-in"
                // variant="h1"
                fontSize="clamp(3.75rem, 3.0357rem + 3.5714vw, 6.25rem)"
                color="#AAFE02"
                fontWeight="bolder"
                fontFamily="Cute"
                lineHeight=".8"
              >
               Second Chanceee!
              </Paragraph>
              <Paragraph
                // data-aos="zoom-in"
                variant="h6"
              >
                Missed out on Borpa? Don’t sweat it!
              </Paragraph>
              <Paragraph
                // data-aos="zoom-in"
                maxWidth="500px"
              >
Baby Borpa is your golden ticket to join the party. We’re giving you a second chance to hop on board and ride the wave of meme magic. Invest in Baby Borpa now and be part of the coolest crypto family around!              </Paragraph>
              <CustomButton sx={{filter:"grayscale(1)",cursor:"not-allowed !important"}}>Buy now</CustomButton>
            </CustomGrid>
        </Grid>
      </Container>
    </ContinerWraper>
  );
};

export default Community;
