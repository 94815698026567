import React, { useEffect, useRef } from "react";
import { Box, Container } from "@mui/material";
import styled from "@emotion/styled";
import { CustomGrid, DataImage } from "../../../Styles/style";
import heroVideo from "../../../../assets/images/heroVideo.mp4";
import heroVideoMobile from "../../../../assets/images/heroMobileVideo.mp4";
import pinksale from "../../../../assets/images/pinksale.png";
import LockerMenu from "../../LockerMenu";
import Paragraph from "../../Paragraph";
import CustomButton from "../../CustomBtn";
const ContinerWraper = styled(Box)`
  overflow: hidden;
  min-height: 100dvh;
  width: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  padding: 0 !important;
  margin: 0 !important;
  position: relative;
  z-index: 0;
  @media (max-width: 599px) {
    /* min-height: auto; */
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
`;

const InnerContinerWraper = styled(Container)`
  min-height: calc(100dvh - 90px);
  width: 100%;
  padding: 0 20px !important;
  z-index: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
  @media (max-width: 599px) {
    min-height: auto;
    background-position: left;
    padding: 0 20px !important;
    /* min-height: auto; */

    background-image: none;
  }
`;
const HeroBgVideo = styled.video`
  width: 100%;
  height: 100vh;
  object-fit: cover;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;    
  display : block;
  @media (max-width: 599px) {
    display : none;
  }
`;
const HeroBgVideoMobile = styled.video`
  width: 90%;
  margin: 0 auto;
  height: auto;
  z-index: -1;    
  display : none;
  @media (max-width: 599px) {
    display : block;
  }
`;


const HeroSection = () => {
  const videoRef = useRef(null);

  useEffect(() => {
    const video = videoRef.current;
    if (!video) return;

    video.muted = true; // Ensure the video is muted
    video.playsInline = true; // Ensure the video plays inline
    video.play().catch(error => {
      // Autoplay failed, add a user gesture to play the video
      document.addEventListener('click', () => {
        video.play().catch(error => {
          console.error('Autoplay failed:', error);
        });
      }, { once: true }); // Ensure the event is only triggered once
    });
  }, []);
  return (
    <>
      <ContinerWraper id="home">
      <HeroBgVideo src={heroVideo} autoPlay loop muted/>
        <LockerMenu />
        <InnerContinerWraper  maxWidth="xl">
          <HeroBgVideoMobile ref={videoRef} src={heroVideoMobile} autoPlay loop muted/>
          <CustomGrid container p="40px 0" fd="column" ai="start" jc="start">
            <CustomGrid
              data-aos="fade-right"
              container
              item
              xs={12}
              sm={4}
              md={4}
              ai="start"
              fd="column"
              g="20px"
              // marginTop={{xs:"400px",sm:"0"}}
            >
             <Paragraph
                // data-aos="zoom-in"
                // variant="h1"
                fontSize="clamp(5rem, 2.8571rem + 10.7143vw, 12.5rem)"
                color="#AAFE02"
                fontWeight="bolder"
                fontFamily="Cute"
                lineHeight=".8"
              >
               Baby <br />
               Borpa
              </Paragraph>
              <Paragraph
              >
Welcome to the wild world of Baby Borpa, the cheeky offspring of the legendary Borpa from the BorpaToken universe. We’re here to bring you meme madness and blockchain brilliance with a dash of baby charm. Get ready to hop on the Baby Borpa train and ride the wave to the moon!
</Paragraph>
              <Paragraph
              >
                Join Presale:
              </Paragraph>
              <CustomButton sx={{filter:"grayscale(1)",cursor:"not-allowed !important"}}><DataImage mw="25px" style={{marginRight:"10px"}} src={pinksale} />Pinksale</CustomButton>
            </CustomGrid>
          </CustomGrid>
        </InnerContinerWraper>
      </ContinerWraper>
    </>
  );
};

export default HeroSection;
